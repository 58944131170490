var node = document.getElementById("app")
var music = <HTMLAudioElement>document.getElementById("music")

var texts = [
    { text: "Searching protocol Team Z" },
    { text: "." },
    { text: "." },
    { text: "." },
    { text: ".", newLine: true },
    { text: "Initiating detection stage" },
    { text: "." },
    { text: "." },
    { text: "." },
    { text: ".", newLine: true },
    { text: "Soundtrack detected: " },
    { text: "on ", class:"blue", noAnimation: true, handler: async () => { music.volume = 0.5; music.loop = true; await music.play() }  },
    { text: "off", class:"blue", noAnimation: true, handler: async () => music.pause(), newLine: true },
    { text: "Instagram detected: ", link: "https://www.instagram.com/_team_z__/", newLine: true },
    { text: "Youtube detected: ", name: "https://www.youtube.com/_team_z__/", link: "https://www.youtube.com/channel/UChRItBsU60IzrkCxTFuuVdw?view_as=subscriber", newLine: true },
    { text: "Facebook detected: ", link: "https://www.facebook.com/TeamZ2DGame/", newLine: true },
    { text: "Test sample detected: ", name: "https://drive.google.com/TeamZ/", link: "https://drive.google.com/drive/folders/1NAPHRFHKiz3cNF4DufLPCpmXBg-3_w9D?usp=sharing", newLine: true },
    { text: "Activation date: " },
    { text: " THIS YEAR ", newLine: true, class: "red", noAnimation: true },
    { text: "TeamZ is coming" },
    { text: "." },
    { text: "." },
    { text: "." },
    { text: ".", newLine: true }
]


function newLine() {
    node.appendChild(document.createElement('br'))
}

function showTextDelayed(text: string, style?: string) {
    let promise = new Promise<HTMLElement>(resolve => {
        let count = 0
        let interval = setInterval(() => {
            if (Math.random() < 0.2) {
                return;
            }

            var char = text[count++]
            let element = showText(char, style)
            if (count == text.length) {
                clearInterval(interval)
                resolve(element)
            }
        }, 50)
    })

    return promise;
}

function showText(text: string, style?: string) {
    var textNode = document.createElement("span")
    textNode.textContent = text ? text : text + " "
    if (style) {
        textNode.classList.add(style)
    }

    node.appendChild(textNode)
    
    return textNode
}

function showLink(name: string, url: string) {
    var link = document.createElement("a")
    link.href = url
    link.textContent = name ? name : url
    link.target = "_blank"
    link.classList.add("blue")

    node.appendChild(link)

    return link
}

function delay(time: number) {
    let promise = new Promise(resolve => {
        setTimeout(() => resolve(), time)
    })

    return promise;
}

async function main() {
    for (const entry of texts) {
        let element: HTMLElement = null;
        if (!entry.noAnimation) {
            element = await showTextDelayed(entry.text, entry.class)
        } else {
            element = showText(entry.text, entry.class)
        }

        if (entry.link) {
            showLink(entry.name, entry.link)
        }

        if (entry.handler) {
            element.onclick = entry.handler
        }

        await delay(500)

        if (entry.newLine) {
            newLine()
        }
    }
    while (true) {
        let text = "";
        for (let i = 0; i < Math.random() * 50; i++) {
            text +=  String.fromCharCode(Math.random()* 1200)
        }
        await showTextDelayed(text + " ", "red")
        await delay(500 + Math.random() * 5000)

        if (Math.random() >= 0.5) {
            newLine()
        }
    }
}

main();